import { Link } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useGetWishlistQuery } from '../../../../plugins/gatsby-plugin-redux/store/api/profile.api';
import SectionBookList from './SectionBookList';

type WishlistProps = RouteComponentProps & {
  isCurrentUser: boolean;
}

const Wishlist: FC<WishlistProps> = ({
  isCurrentUser,
}) => {
  const {
    data: listBooks,
    isLoading: isLoadingListBooks,
  } = useGetWishlistQuery(isCurrentUser ? undefined : skipToken);

  return (
    <SectionBookList
      books={listBooks}
      isLoading={isLoadingListBooks}
      errorMessage={(
        <>
          You haven&apos;t added a book to your wishlist. Visit
          {' '}
          <Link href="/">the store</Link>
          {' '}
          to find an interesting book to read
        </>
        )}
    />
  );
};

export default Wishlist;
