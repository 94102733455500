import {
  Link, Tab,
} from '@mui/material';
import { RouteComponentProps, Router } from '@reach/router';
import { FC } from 'react';
import Tabs from '../../app/Tabs';
import PurchasedBooks from './PurchasedBooks';
import Wishlist from './Wishlist';

type LibraryProps = RouteComponentProps & {
  username: string;
  isCurrentUser: boolean;
}

const Library: FC<LibraryProps> = ({
  isCurrentUser,
  username,
  location,
}) => (
  <>
    {isCurrentUser && (
      <Tabs
        value={location?.pathname?.endsWith('/') ? location.pathname.slice(0, -1) : location?.pathname}
        marginBottom={5}
      >
        <Tab label="Purchased Books" value={`/${username}/library`} href={`/${username}/library`} component={Link} />
        <Tab label="Wishlist" value={`/${username}/library/wishlist`} href={`/${username}/library/wishlist`} component={Link} />
      </Tabs>
    )}

    <Router basepath={`/${username}/library`}>
      {isCurrentUser && <PurchasedBooks path="/" isCurrentUser username={username} />}
      {isCurrentUser && <Wishlist path="/wishlist" isCurrentUser />}
    </Router>
  </>
);

export default Library;
