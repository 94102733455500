import { Link, Tab } from '@mui/material';
import { Router } from '@reach/router';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { navigate, PageProps } from 'gatsby';
import { useEffect } from 'react';
import { useGetProfileQuery } from '../../plugins/gatsby-plugin-redux/store/api/profile.api';
import useAuth from '../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import CircularProgress from '../components/app/CircularProgress';
import Container from '../components/app/Container';
import SEO from '../components/app/SEO';
import Tabs from '../components/app/Tabs';
import Library from '../components/pages/profile/Library';
import Overview from '../components/pages/profile/Overview';
import Wallet from '../components/pages/profile/Wallet';

const Profile = ({ params, location }: PageProps) => {
  const username = params.username?.split('/').shift() as string;
  const auth = useAuth();

  const {
    data: profile,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetProfileQuery(username);

  const isCurrentUser = username === auth?.username;

  useEffect(() => {
    const exception = error as FetchBaseQueryError;

    if (isError && exception?.status === 404) {
      void navigate('/404');
    }
  }, [error, isError]);

  if (isLoading || isFetching) {
    return <CircularProgress center sx={{ marginTop: 5 }} />;
  }

  if (!profile) {
    return null;
  }

  return (
    <Container sx={{ paddingY: 3 }} maxWidth="lg">
      <SEO title={`${username}'s profile`} />

      {isCurrentUser && (
        <Tabs
          value={location?.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname}
          marginBottom={5}
        >
          <Tab label="Overview" value={`/${username}`} href={`/${username}`} component={Link} />
          <Tab label="Library" value={`/${username}/library`} href={`/${username}/library`} component={Link} />
          <Tab label="Wallet" value={`/${username}/wallet`} href={`/${username}/wallet`} component={Link} />
        </Tabs>
      )}

      <Router basepath={`/${username}`}>
        <Overview path="/" profile={profile} />
        {isCurrentUser && <Library path="/library/*" isCurrentUser username={username} />}
        {isCurrentUser && <Wallet path="/wallet" />}
      </Router>

    </Container>
  );
};

export default Profile;
