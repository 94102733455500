import { Typography, Stack, Box } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import {
  FC, useState,
} from 'react';
import BigNumber from 'bignumber.js';
import useAppDispatch from '../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import {
  useGetWalletTransactionsQuery,
} from '../../../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import { addSnackbar } from '../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import Button from '../../app/Button';
import FlutterwaveSDK from '../../app/FlutterwaveSDK';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { getCurrencySymbol } from '../../../helpers/formatAmount';
import FundWalletFlow from './FundWalletFlow';
import TransactionList from './TransactionList';

type WalletProps = RouteComponentProps;

const Wallet: FC<WalletProps> = () => {
  const auth = useAuth();

  const [isFundWalletFlowOpen, setIsFundWalletFlowOpen] = useState(false);

  const {
    data: walletTransactions = [],
    refetch,
    isLoading: isLoadingTransactions,
  } = useGetWalletTransactionsQuery();

  const dispatch = useAppDispatch();

  const [integral, fractional] = new BigNumber(auth?.balance || 0).dividedBy(100).toFixed(2).split('.');

  return (
    <>
      <FlutterwaveSDK />

      <FundWalletFlow
        isOpen={isFundWalletFlowOpen}
        onComplete={() => {
          dispatch(addSnackbar({ variant: 'success', message: 'Wallet successfully funded' }));
          refetch();
        }}
        onClose={() => setIsFundWalletFlowOpen(false)}
      />

      <Box display="flex" flexDirection="column" alignItems="center" marginBottom={3}>
        <Typography gutterBottom variant="body2" color="text.secondary">
          Wallet Balance
        </Typography>

        <Stack flexDirection="row" alignItems="baseline">
          <Typography variant="h5" component="span">
            {getCurrencySymbol(auth?.currencyCode)}
          </Typography>
          <Typography variant="h3" component="span" fontWeight="bold">
            {integral}
          </Typography>
          <Typography variant="h5" component="span">
            {`.${fractional}`}
          </Typography>
        </Stack>

        <Button
          onClick={() => setIsFundWalletFlowOpen(true)}
          sx={{ marginTop: 5 }}
          disableElevation
        >
          Fund wallet
        </Button>
      </Box>

      <TransactionList
        transactions={walletTransactions}
        isLoading={isLoadingTransactions}
        refetch={refetch}
      />
    </>
  );
};

export default Wallet;
