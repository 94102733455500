import {
  Typography, Stack, Box, Grid,
} from '@mui/material';
import { ReactNode, VFC } from 'react';
import { BookInfoSchema, BookSchema } from '../../../types/schema';
import BookVerticalItem from '../../app/BookVerticalItem';
import CircularProgress from '../../app/CircularProgress';
import IconEmpty from '../../icons/IconEmpty';

type SectionBookListProps = {
  books?: BookInfoSchema[];
  title?: string;
  isLoading: boolean;
  errorMessage: ReactNode;
  isReader?: boolean;
}

const SectionBookList: VFC<SectionBookListProps> = ({
  books,
  title,
  isLoading,
  errorMessage,
}) => (
  <Box>
    {title && <Typography marginBottom={2} variant="h5" fontWeight="600">{title}</Typography>}

    {isLoading && <CircularProgress center />}
    {!isLoading && !!books?.length && (
    <Grid container>
      {books.map((book) => (
        <Grid item key={book.slug}>
          <BookVerticalItem
            key={book.slug}
            hidePrice
            isReader
            {...book as unknown as BookSchema}
          />
        </Grid>
      ))}
    </Grid>
    )}
    {!isLoading && !books?.length && (
    <Stack alignItems="center">
      <Box width={400} maxWidth="100%" textAlign="center" marginTop={4}>
        <IconEmpty />
        <Typography marginTop={3}>
          {errorMessage}
        </Typography>
      </Box>
    </Stack>
    )}
  </Box>
);

export default SectionBookList;
