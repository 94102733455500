import { ComponentProps } from 'react';
import Svg from '../app/Svg';

const IconEmpty = (props: ComponentProps<typeof Svg>) => (
  <Svg width="112" height="70" viewBox="0 0 112 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M36.2655 13.3333L26.1453 62.635L24.8302 69.0892L92.2985 68.7801C95.3288 68.7665 98.0161 66.2146 98.9853 62.4234L110.952 15.8797C111.693 13.0024 110.069 10.0085 107.77 10.0112L39.4674 10.079C37.9808 10.0763 36.6486 11.4106 36.2655 13.3333Z" fill="#004E89" />
    <g opacity="0.5">
      <path opacity="0.5" d="M36.2655 13.3333L26.1453 62.635L24.8302 69.0892L92.2985 68.7801C95.3288 68.7665 98.0161 66.2146 98.9853 62.4234L110.952 15.8797C111.693 13.0024 110.069 10.0085 107.77 10.0112L39.4674 10.079C37.9808 10.0763 36.6486 11.4106 36.2655 13.3333Z" fill="black" />
    </g>
    <path d="M85.8347 59.2884L78.6876 4.66873C78.4018 2.43144 76.5178 0.760937 74.2965 0.769073L58.6301 0.815174C58.0479 0.821399 57.4732 0.940022 56.9409 1.16381C56.4086 1.38761 55.9299 1.71188 55.534 2.11687L48.1011 9.6206L4.5326 9.84297C1.87103 9.85653 -0.175887 12.2484 0.175749 14.9331L6.81108 65.4145C7.09696 67.6491 8.98093 69.3169 11.1965 69.3142L87.1412 69.1704C95.5976 69.3359 96.4066 66.9684 96.4066 66.9684C86.6981 69.005 85.8347 59.302 85.8347 59.2884Z" fill="#004E89" />
    <g opacity="0.5">
      <path opacity="0.5" d="M53.0868 48.3651L50.5253 50.9739L30.7994 34.1386L33.3609 31.5298L53.0868 48.3651Z" fill="white" />
      <path opacity="0.5" d="M49.4533 30.9521L52.2835 33.3684L34.4359 51.5515L31.6028 49.1352L49.4533 30.9521Z" fill="white" />
    </g>
  </Svg>
);

export default IconEmpty;
