import {
  Box, Tabs as BaseTabs, TabsProps as BaseTabsProps,
} from '@mui/material';
import { FC } from 'react';

type TabProps = BaseTabsProps & {
  marginBottom?: number;
  hideUnderline?: boolean;
};

const Tabs: FC<TabProps> = ({
  children,
  marginBottom,
  hideUnderline = false,
  ...props
}) => (
  <Box sx={{
    marginBottom,
    borderBottom: !hideUnderline ? 1 : 0,
    borderBottomColor: (theme) => theme.palette.divider,
    borderBottomStyle: 'solid',
  }}
  >
    <BaseTabs
      allowScrollButtonsMobile
      variant="scrollable"
      {...props}
    >
      {children}
    </BaseTabs>
  </Box>
);

export default Tabs;
