import {
  Typography, Card, CardContent, Box, Grid,
} from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import nl2br from '../../../helpers/nl2br';
import { ProfileSchema } from '../../../types/schema';
import UserAvatar from '../../app/UserAvatar';

type OverviewProps = RouteComponentProps & {
  profile: ProfileSchema;
};

const Overview: FC<OverviewProps> = ({
  profile,
}) => (
  <>
    <Grid container columnSpacing={2} alignItems="center" marginBottom={4}>
      <Grid item xs="auto">
        <UserAvatar
          src={profile?.avatar}
          name={profile?.fullName}
          sx={{ width: '100px', height: '100px' }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h5">{profile?.fullName}</Typography>
        <Typography>
          {`(${profile?.username})`}
        </Typography>
      </Grid>
    </Grid>

    <Box>
      <Typography marginBottom={2} variant="h5">About</Typography>
      <Card>
        <CardContent
          dangerouslySetInnerHTML={{ __html: nl2br(profile?.about) }}
        />
      </Card>
    </Box>
  </>
);

export default Overview;
