import {
  Link, Theme, useMediaQuery, Chip, Box,
} from '@mui/material';
import { useCallback, useMemo, VFC } from 'react';
import useAuth from '../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { BOOK_COVER_HEIGHT, BOOK_COVER_WIDTH } from '../../constants';
import { toGtagItem, trackSelectItem } from '../../helpers/gtag';
import { BookSchema } from '../../types/schema';
import BookCover from './BookCover';

type BookVerticalItemProps = Partial<BookSchema> & {
  isReader?: boolean;
};

const BookVerticalItem: VFC<BookVerticalItemProps> = ({
  slug,
  cover,
  pricings,
  pricingType,
  title,
  isReader,
  releaseDate,
}) => {
  const auth = useAuth();
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.between('xs', 'md'));

  const imgWidth = useMemo(() => BOOK_COVER_WIDTH + (!matches ? 80 : 0), [matches]);
  const imgHeight = useMemo(() => BOOK_COVER_HEIGHT + (!matches ? 80 : 0), [matches]);

  const handleClick = useCallback(() => {
    trackSelectItem('Book Vertical Item', toGtagItem(
      [{
        title, slug, pricings, pricingType,
      }],
      { currencyCode: auth?.currencyCode, quantity: 1, listName: 'Book Vertical Item' },
    )[0]);
  }, [auth?.currencyCode, pricingType, pricings, slug, title]);

  return (
    <Box component="section">
      <Box
        component={Link}
        href={isReader ? `/app/reader/${slug}` : `/b/${slug}`}
        color="inherit"
        underline="none"
        onClick={handleClick}
        sx={(theme) => ({
          [theme.breakpoints.between('xs', 'md')]: {
            width: BOOK_COVER_WIDTH,
            padding: 0,
          },
          width: BOOK_COVER_WIDTH + 80,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: theme.spacing(2),
          borderRadius: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
            },
          },
        })}
      >
        <Box position="relative">
          <BookCover
            src={cover!}
            width={imgWidth}
            height={imgHeight}
            borderRadius={2}
          />
          {releaseDate && (
          <Chip
            color={!releaseDate ? 'error' : 'info'}
            sx={{ position: 'absolute', bottom: 10, left: 10 }}
            label={!releaseDate ? 'Out of stock' : 'Preorder'}
          />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookVerticalItem;
