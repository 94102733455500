import { Link } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useGetPurchasedBooksQuery } from '../../../../plugins/gatsby-plugin-redux/store/api/profile.api';
import SectionBookList from './SectionBookList';

type PurchasedBooksProps = RouteComponentProps & {
  username: string;
  isCurrentUser: boolean;
}

const PurchasedBooks: FC<PurchasedBooksProps> = ({
  username,
  isCurrentUser,
}) => {
  const {
    data: purchasedBooks,
    isLoading: isLoadingPurchasedBooks,
  } = useGetPurchasedBooksQuery(isCurrentUser ? username : skipToken);

  return (
    <SectionBookList
      books={purchasedBooks}
      isLoading={isLoadingPurchasedBooks}
      isReader
      errorMessage={(
        <>
          You haven&apos;t purchased any book. Visit
          {' '}
          <Link href="/">the store</Link>
          {' '}
          to find an interesting book to read
        </>
        )}
    />
  );
};

export default PurchasedBooks;
