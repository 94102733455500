import {
  ArrowUpward, ArrowDownward, ArrowForwardIos, Replay,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Tab, Chip, List, ListItem, ListItemButton,
  ListItemAvatar, ListItemText, Typography, Stack,
  IconButton, Divider, DialogActions,
  DialogContent,
} from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  FC,
  Fragment, useCallback, useMemo, useState,
} from 'react';
import useAppDispatch from '../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useVerifyTransactionMutation } from '../../../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import { addSnackbar } from '../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { getErrorMessage, ErrorType } from '../../../helpers/handleSubmitAction';
import localizedDate from '../../../helpers/localizedDate';
import timeago from '../../../helpers/timeago';
import Money from '../../../services/Money';
import { WalletTransactionSchema } from '../../../types/schema';
import CircularProgress from '../../app/CircularProgress';
import Dialog from '../../app/Dialog';
import DialogTitle from '../../app/DialogTitle';
import NoData from '../../app/NoData';
import Tabs from '../../app/Tabs';

type TransactionListProps = {
  transactions: WalletTransactionSchema[];
  refetch: () => void;
  isLoading: boolean;
  depositLabel?: string;
  withdrawLabel?: string;
  isAuthor?: boolean;
  noDataMessage?: string;
}

const TransactionList: FC<TransactionListProps> = ({
  transactions,
  refetch,
  isLoading,
  depositLabel = 'Deposit',
  withdrawLabel = 'Withdraw',
  isAuthor = false,
  noDataMessage = 'No transactions yet. Start by funding your wallet.',
}) => {
  const [filterTab, setFilterTab] = useState<'all' | 'withdrawal' | 'deposit'>('all');

  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<WalletTransactionSchema | null>(null);

  const [
    verifyTransactionAction,
    { isLoading: isVerifyingTransaction },
  ] = useVerifyTransactionMutation();

  const dispatch = useAppDispatch();

  const filteredWalletTransaction = useMemo(() => (
    transactions?.filter((transaction) => (
      filterTab === 'all' ? transaction : transaction.type === filterTab
    ))
  ), [transactions, filterTab]);

  const transactionDetails = useMemo(() => ([
    {
      text: 'Title',
      value: selectedTransaction?.title,
      hide: !selectedTransaction?.title,
    },
    {
      text: 'Reference',
      value: selectedTransaction?.reference,
    },
    {
      text: 'Voucher',
      value: selectedTransaction?.voucher?.code || 'N/A',
      hide: !selectedTransaction?.voucher,
    },
    {
      text: 'Discount',
      value: selectedTransaction
        ? Money.of(selectedTransaction?.voucher?.price, selectedTransaction?.currencyCode)
          .multiply(-1)
          .toFormat()
        : 0,
      hide: !selectedTransaction?.voucher,
    },
    {
      text: 'Amount',
      value: selectedTransaction?.amount
        ? Money.of(selectedTransaction?.amount, selectedTransaction?.currencyCode)
          .toFormat()
        : 0,
    },
    {
      text: 'Shipping Fee',
      value: selectedTransaction
        ? Money.of(selectedTransaction?.shippingFee, selectedTransaction?.currencyCode)
          .multiply(-1)
          .toFormat()
        : 0,
      hide: isAuthor || selectedTransaction?.type === 'deposit',
    },
    {
      text: 'Royalty',
      value: `${new BigNumber(Number(selectedTransaction?.royalty || 0)).multipliedBy(100).toFixed()}%`,
      hide: !isAuthor,
    },
    {
      text: 'Status',
      value: selectedTransaction?.confirmed ? 'Confirmed' : 'Pending',
    },
    {
      text: 'Date',
      value: selectedTransaction?.date ? localizedDate(selectedTransaction?.date) : null,
    },
  ]), [selectedTransaction, isAuthor]);

  const handleReverifyTransaction = useCallback(async () => {
    if (!selectedTransaction?.reference) {
      return;
    }

    try {
      await verifyTransactionAction({ reference: selectedTransaction.reference }).unwrap();
      refetch();
      setSelectedTransaction(null);
    } catch (error) {
      dispatch(addSnackbar({
        message: getErrorMessage(error as ErrorType),
        variant: 'error',
      }));
    }
  }, [dispatch, refetch, selectedTransaction?.reference, verifyTransactionAction]);

  return (
    <>
      <Dialog open={!!selectedTransaction} onClose={() => setSelectedTransaction(null)} maxWidth="sm" fullWidth>
        <DialogTitle>Transaction details</DialogTitle>
        <DialogContent>
          <List dense>
            {transactionDetails.filter((item) => !item.hide).map((item) => (
              <ListItem key={item.text} disableGutters>
                <ListItemText primary={item.text} secondary={item.value} />
              </ListItem>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          {!selectedTransaction?.confirmed && selectedTransaction?.type === 'deposit' && (
          <LoadingButton
            size="small"
            title="Reverify transaction"
            startIcon={<Replay />}
            fullWidth
            loading={isVerifyingTransaction}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleReverifyTransaction}
          >
            Retry
          </LoadingButton>
          )}

        </DialogActions>
      </Dialog>

      <Tabs
        hideUnderline
        value={filterTab}
        onChange={(_, filter: typeof filterTab) => setFilterTab(filter)}
      >
        <Tab label="All" value="all" />
        <Tab label={depositLabel} value="deposit" />
        <Tab label={withdrawLabel} value="withdraw" />
      </Tabs>

      {isLoading && (
      <CircularProgress center />
      )}

      {!isLoading && !filteredWalletTransaction?.length && (
        <NoData message={noDataMessage} />
      )}

      {!isLoading && !!filteredWalletTransaction?.length && (
      <List sx={{ bgcolor: 'background.paper' }}>
        {filteredWalletTransaction?.map((transaction, index, { length }) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index.toString()}>
            <ListItem
              alignItems="flex-start"
              disablePadding
            >
              <ListItemButton onClick={() => setSelectedTransaction(transaction)}>
                <ListItemAvatar>
                  {transaction.type === 'deposit'
                    ? <ArrowUpward color="success" />
                    : <ArrowDownward color="error" />}
                </ListItemAvatar>
                <ListItemText
                  primary={(
                      isAuthor ? `${transaction.title}` : `${transaction.reference || ''}`
                  )}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={(
                    <Stack flexDirection="row" columnGap={2}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                        fontWeight="600"
                      >
                        {Money.of(transaction.amount, transaction.currencyCode).toFormat()}
                      </Typography>
                      {'\u2022'}
                      <Typography
                        component="time"
                        variant="body2"
                        color="text.primary"
                        dateTime={transaction.date}
                      >
                        {timeago(transaction.date)}
                      </Typography>
                    </Stack>
                )}
                />

                {!isAuthor
                  && (
                  <Chip
                    size="small"
                    variant={transaction.confirmed ? 'filled' : 'outlined'}
                    label={transaction.confirmed ? 'Confirmed' : 'Pending'}
                  />
                  )}
                <IconButton edge="end" disableRipple size="small">
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </ListItemButton>
            </ListItem>
            {index + 1 !== length && <Divider variant="fullWidth" component="li" />}
          </Fragment>
        ))}
      </List>
      )}

    </>
  );
};

export default TransactionList;
