import { ServerResponse } from '../../../../src/types/app';
import { ProfileSchema, PurchasedBookSchema, ListSchema } from '../../../../src/types/schema';
import { api } from './index';

const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileSchema, string>({
      query: (slug) => `profile/${slug}`,
      transformResponse: (response: ServerResponse<ProfileSchema> & { email: string; }) => ({
        ...response.data,
        email: response.email,
      }),
    }),
    getPurchasedBooks: builder.query<PurchasedBookSchema[], string>({
      query: (username) => `dashboard/profile/${username}/purchased-books`,
      transformResponse: (response: ServerResponse<PurchasedBookSchema[]>) => response.data,
    }),
    getWishlist: builder.query<ListSchema[], void>({
      query: () => 'dashboard/wishlists',
      transformResponse: (response: ServerResponse<ListSchema[]>) => response.data,
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetPurchasedBooksQuery,
  useGetWishlistQuery,
} = profileApi;
