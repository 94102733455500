import { Stack, Typography, Box } from '@mui/material';
import { VFC } from 'react';
import IconEmpty from '../icons/IconEmpty';

type NoDataProps = {
  message: string;
}

const NoData: VFC<NoDataProps> = ({ message }) => (
  <Stack alignItems="center">
    <Box width={400} maxWidth="100%" textAlign="center">
      <IconEmpty />
      <Typography marginTop={2}>
        {message}
      </Typography>
    </Box>
  </Stack>
);

export default NoData;
